var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "datadource-formula-container" },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "100px" } },
        [
          _c("el-input", {
            staticClass: "formula-result",
            attrs: { size: _vm.size, placeholder: _vm.placeholder },
            on: { focus: _vm.openVariableConfigDialog },
            model: {
              value: _vm.formula,
              callback: function ($$v) {
                _vm.formula = $$v
              },
              expression: "formula",
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "formula-dialog",
          attrs: {
            title: _vm.$t("lang_form_configuration_expression"),
            visible: _vm.dialogFlag,
            width: "1000px",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFlag = $event
            },
          },
        },
        [
          _c("div", { staticClass: "formula-content" }, [
            _c(
              "div",
              { staticClass: "formula-factor" },
              [
                _c(
                  "el-tabs",
                  {
                    staticClass: "formula-factor-tabs",
                    attrs: { type: "border-card" },
                    model: {
                      value: _vm.factorTypeVal,
                      callback: function ($$v) {
                        _vm.factorTypeVal = $$v
                      },
                      expression: "factorTypeVal",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      {
                        staticClass: "formula-factor-tab-item",
                        attrs: { label: _vm.$t("lang_function"), name: "func" },
                      },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.functionVal,
                            callback: function ($$v) {
                              _vm.functionVal = $$v
                            },
                            expression: "functionVal",
                          },
                        }),
                        _c(
                          "vxe-table",
                          {
                            ref: "funcTableRef",
                            staticClass: "formula-factor-functions",
                            attrs: {
                              "row-id": "type",
                              "max-height": "360",
                              "show-header": false,
                              data: _vm.functionList,
                              "radio-config": {
                                trigger: "row",
                                checkMethod: _vm.canSelectFunction,
                              },
                              "row-class-name": "formula-factor-function-item",
                            },
                            on: { "cell-click": _vm.addFunction },
                          },
                          [
                            _c("vxe-table-column", {
                              attrs: { type: "radio", title: "name" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("span", [_vm._v(_vm._s(row.name))]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      {
                        staticClass: "formula-factor-tab-item",
                        attrs: { label: _vm.$t("lang_field"), name: "field" },
                      },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.fieldVal,
                            callback: function ($$v) {
                              _vm.fieldVal = $$v
                            },
                            expression: "fieldVal",
                          },
                        }),
                        _c(
                          "vxe-table",
                          {
                            ref: "fieldTableRef",
                            staticClass: "formula-factor-functions",
                            attrs: {
                              "row-id": "id",
                              "show-header": false,
                              "max-height": "360",
                              data: _vm.fieldTree,
                              "tree-config": {
                                children: "children",
                                indent: 10,
                              },
                              "radio-config": {
                                trigger: "row",
                                checkMethod: _vm.canSelectField,
                              },
                              "row-class-name": "formula-factor-function-item",
                            },
                            on: { "cell-click": _vm.addField },
                          },
                          [
                            _c("vxe-table-column", {
                              attrs: {
                                "tree-node": "",
                                type: "radio",
                                title: "name",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t(row.text))),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      {
                        staticClass: "formula-factor-tab-item",
                        attrs: {
                          label: _vm.$t("lang_other"),
                          name: "constants",
                        },
                      },
                      [
                        _c(
                          "vxe-table",
                          {
                            ref: "otherTableRef",
                            staticClass: "formula-factor-functions",
                            attrs: {
                              "row-id": "code",
                              "max-height": "360",
                              "show-header": false,
                              data: _vm.otherFactorList,
                              "radio-config": {
                                trigger: "row",
                                checkMethod: _vm.canSelectConstant,
                              },
                              "row-class-name": "formula-factor-function-item",
                            },
                            on: { "cell-click": _vm.addConstant },
                          },
                          [
                            _c("vxe-table-column", {
                              attrs: { type: "radio", title: "text" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("span", [_vm._v(_vm._s(row.text))]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "formula-structure" }, [
              _c("span", { staticClass: "formula-structure-remark" }, [
                _vm._v(" " + _vm._s(_vm.$t("lang_adding_an_element")) + " "),
              ]),
              !_vm.factorList || _vm.factorList.length === 0
                ? _c("div", { staticClass: "formula-structure-empty" }, [
                    _vm._v(_vm._s(_vm.$t("lang_expression_factor_from"))),
                  ])
                : _c(
                    "div",
                    { staticClass: "formula-structure-content" },
                    [
                      _c("formual-nested", {
                        ref: "factorRef",
                        on: { select: _vm.changeActivedFactor },
                        model: {
                          value: _vm.factorList,
                          callback: function ($$v) {
                            _vm.factorList = $$v
                          },
                          expression: "factorList",
                        },
                      }),
                    ],
                    1
                  ),
            ]),
          ]),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFlag = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveFormula } },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }