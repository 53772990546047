


















import { Vue, Prop, Component, InjectReactive, Watch } from 'vue-property-decorator';
import { HtmlChildModel, HtmlTypeEnum } from '@/models/form/FormModel';
import draggable from 'vuedraggable';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
@Component({
  name: 'HtmlNode',
  components: {
    draggable
  }
})
export default class HtmlNode extends Vue {
  @Prop({ required: true }) node!: HtmlChildModel[];
  prop: Record<string, any> = {
    label: 'name',
    children: 'children'
  };
  @InjectReactive() formId;
  @InjectReactive('allDataSource') allDataSource;
  get _componentsMap() {
    return Object.keys(this.$store.getters.formWidgetMap[this.formId])
      .filter(a => !a.match(/html|row|table|link/))
      .map(a => {
        return {
          compCode: this.$store.getters.formWidgetMap[this.formId][a].compCode,
          name: this.$store.getters.formWidgetMap[this.formId][a].compName
        };
      });
  }
  /* created(){
    // 初始化时生成label，因为后端不存的
    let arr = Array.from(this.node)
    this.$emit("update:node",arr)
  } */
  deleteHandler(index: number) {
    let arr = Array.from(this.node);
    arr.splice(index, 1);
    this.$emit('update:node', arr);
    this.$emit('delete');
  }
  editHandler(item: HtmlChildModel, node: HtmlChildModel[], index: number) {
    this.$emit('edit', item, node, index);
  }
  changeHandler(e) {
    console.log(e);
    let newIndex = e.newIndex;
    let arr = Array.from(this.node);
    if (e.pullMode === 'clone') {
      let node = {
        name: e.item.attributes['data-type'].value,
        props: [
          { name: 'style', value: '' },
          { name: 'class', value: '' }
        ],
        children: [],
        id: SystemUtil.uuid()
      };
      if (e.item.attributes['data-type'].value === 'a') {
        node.props.push({ name: 'href', value: '' });
      }
      arr.splice(newIndex, 1, node);
    }
    this.$emit('update:node', arr);
  }
  @Watch('content.label')
  changeLabel(newVal) {
    console.log(newVal);
  }
}
