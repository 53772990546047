













































































































































































































import { Component, Mixins, InjectReactive, ProvideReactive, Watch } from 'vue-property-decorator';
import ElePropertiesMixins from './mixins/ElePropertiesMixins';
import { getWidgetConfigStruct } from '@/api/form-design/FormApi';
import HtmlNode from '../components/html-node.vue';
import draggable from 'vuedraggable';
import EleDataSourceConfigDialog from '../ele-data-source-config-dialog.vue';
import EleFormulaConfigForHtml from '../ele-formula-config-for-html.vue';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import { WidgetData, WidgetModel } from '@/models/form/WidgetModel';
import EleFormulaConfig from '../ele-formula-config.vue';
import { WidgetDataSourceConfigData, WidgetDataSourceConfigModel, WidgetDataSourceData } from '@/models/form/WidgetDataSourceModel';
import { HtmlChildModel, HtmlModel, HtmlTypeEnum } from '@/models/form/FormModel';
interface CustomDataSourceModel extends WidgetDataSourceConfigModel {
  link?: boolean;
}
/**
 * text的配置项
 */
@Component({
  name: 'htmlConfig',
  components: {
    HtmlNode,
    EleDataSourceConfigDialog,
    draggable,
    EleFormulaConfig,
    EleFormulaConfigForHtml
  }
})
export default class extends Mixins(ElePropertiesMixins) {
  editTextVisible: boolean = false;
  @InjectReactive() formId;
  @ProvideReactive('allDataSource') allDataSource;
  tags: any[] = [];
  current: string = 'datasource';
  currentNode: HtmlChildModel | null = null;
  cachedWidget: WidgetModel = WidgetData;
  vis_source: boolean = false;
  vis_node: boolean = false;
  vis_components: boolean = false;
  node: HtmlModel = {
    node: {
      name: 'div',
      props: [{ name: 'class', value: 'node-root' }],
      children: []
    }
  };
  tip: any[] = [
    { value: 'node-link-image', label: this.$t('lang_form_datasource_user_sign') },
    { value: 'node-content-sign', label: '签名容器' },
    { value: 'node-content-textarea', label: '带边框容器' },
    { value: 'node-content-advise', label: '单条意见容器' },
    { value: 'node-grid', label: '网格容器' },
    { value: 'node-grid-label', label: '网格内标签' }
  ];
  allDataSourceConfig: CustomDataSourceModel[] = [];
  selectedComponent: any = [];
  get dataSourceConfigs() {
    let typeMap: Record<string, any> = this.$store.getters.dataSourceStruct;
    return Object.keys(typeMap).map(a => {
      return {
        type: typeMap[a].type,
        name: typeMap[a].name
      };
    });
  }
  get _componentsMap() {
    return Object.keys(this.$store.getters.formWidgetMap[this.formId])
      .filter(a => !a.match(/html|row|table|link/))
      .map(a => {
        return {
          compCode: this.$store.getters.formWidgetMap[this.formId][a].compCode,
          name: this.$store.getters.formWidgetMap[this.formId][a].compName
        };
      });
  }
  get _selectedCol() {
    if ((<Record<string, any>>this.currentNode?.content?.value).id) {
      let target = this.allDataSourceConfig.find(a => a.id === (<Record<string, any>>this.currentNode?.content?.value).id);
      if (target) {
        return target.columnConfig.customComplexColumns;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
  // 打开数据源设置
  openDataSourceConfigDialog(item) {
    this.cachedWidget = SystemUtil.cloneDeep(this.widget);
    let defaultDataSourceConfig = Object.assign(WidgetDataSourceConfigData, item);
    if (item.type !== 'OPTION_CONFIG') {
      this.cachedWidget.dataSource.type = 'COMPLEX';
    }
    this.cachedWidget.dataSource.dataSourceConfig = defaultDataSourceConfig;
    this.$nextTick(() => {
      this.vis_source = true;
    });
  }
  // 编辑已保存数据源
  editDataSourceConfigDialog(item: WidgetDataSourceConfigModel) {
    this.cachedWidget = SystemUtil.cloneDeep(this.widget);
    this.cachedWidget.dataSource.type = 'COMPLEX';
    this.cachedWidget.dataSource.dataSourceConfig = item;
    setTimeout(() => {
      this.vis_source = true;
    }, 0);
  }
  deleteDataSourceConfig(index) {
    this.allDataSourceConfig.splice(index, 1);
  }
  openComponentsConfig() {
    this.vis_components = true;
  }
  saveComponent() {
    this.vis_components = false;
  }
  // 修改是否循环
  changeLoop() {
    (<Record<string, any>>this.currentNode!.content!.value).formula = {};
    (<Record<string, any>>this.currentNode!.content!.value).id = '';
  }
  // 修改组件
  changeComponent(v) {
    // 生成组件label
    this.$set(<object>this.currentNode!.content, 'name', this._componentsMap.find(a => a.compCode === v)?.name);
  }
  // 修改公式
  changeFormula(formula, node, label) {
    node.content.name = label;
    if (node.content.type === HtmlTypeEnum.DATASOURCE) {
      node.content.value.formula = formula;
    } else {
      node.content.value = formula;
    }
  }
  changeHref(formula, col) {
    col.value = formula;
  }
  changeConstant(v) {
    this.currentNode!.content!.name = v;
  }
  changeDataSource(e) {
    let target = this.allDataSourceConfig.find(a => a.id === e);
    if (target) {
      (this.currentNode!.content!.value as Record<string, any>).column = target.columnConfig.customComplexColumns[0].key;
      this.$set(<any>this.currentNode!.content, 'name', target.columnConfig.customComplexColumns[0].title);
    }
  }
  changeColumn(v) {
    let target = this._selectedCol.find(a => a.key === v);
    if (target) {
      this.currentNode!.content!.name = target.title;
    }
  }
  // 修改数据来源类型
  changeType(e) {
    // console.log(e)
    this.currentNode!.content = {
      name: '',
      value: '',
      type: e
    };
    if (e === HtmlTypeEnum.DATASOURCE && this.currentNode?.content) {
      this.$set(this.currentNode.content, 'value', {
        loop: false,
        column: '',
        id: '',
        formula: {}
      });
    }
  }
  /**
   * description  配置数据源弹框 确定
   * @param {}
   * @returns {}
   */
  saveDataSourceConfig(widget) {
    let result = widget.dataSource.dataSourceConfig;
    if (result.id) {
      this.allDataSourceConfig.splice(
        this.allDataSourceConfig.findIndex(a => a.id === result.id),
        1,
        result
      );
      this.checkDataSourceRelated();
    } else {
      result.id = SystemUtil.uuid();
      result.related = new Set();
      result.link = false;
      this.allDataSourceConfig.push(result);
    }
    this.$nextTick(() => {
      this.vis_source = false;
    });
  }
  handlerEditMore() {
    this.editTextVisible = true;
  }
  //   保存数据源
  handlerSave() {
    this.widget.dataSourceConfigs = this.allDataSourceConfig;
    this.widget.node = this.node.node;
    this.$emit('change', this.widget);
    this.editTextVisible = false;
  }
  //   编辑节点
  handleEditNode(item: HtmlChildModel, node: HtmlChildModel[], index: number) {
    this.currentNode = item;
    if (!this.currentNode.content) {
      this.$set(this.currentNode, 'content', {
        type: HtmlTypeEnum.CONSTANT,
        value: '',
        name: ''
      });
    }
    this.$nextTick(() => {
      this.vis_node = true;
    });
  }

  //   关闭编辑节点
  closeNodeEdit() {
    this.clearRelated();
    this.checkDataSourceRelated();
    this.currentNode = null;
    this.vis_node = false;
    // this.$forceUpdate();
  }
  clearRelated() {
    for (let i of this.allDataSourceConfig) {
      if (i.related) {
        i.related.clear();
      }
    }
  }
  //   删除node
  deleteNode() {
    this.checkDataSourceRelated();
  }
  //   判断是否锁定数据源
  checkDataSourceRelated() {
    this.clearRelated();
    this.buildDataSourceRelated(this.node.node.children);
    for (let i of this.allDataSourceConfig) {
      if (i.related && i.related.size) {
        i.link = true;
      } else {
        i.link = false;
      }
    }
  }
  //   先序遍历node树，给所有datasourceConfig添加related
  buildDataSourceRelated(nodes: HtmlChildModel[]): void {
    for (let node of nodes) {
      if (node.children && node.children.length) {
        this.buildDataSourceRelated(node.children);
      }
      if (node.content?.type === HtmlTypeEnum.DATASOURCE) {
        if ((node.content.value as any).id) {
          let target = this.allDataSourceConfig.find(a => {
            return a.id === (node.content!.value as any).id;
          });
          if (target) {
            if (!target.related) {
              target.related = new Set();
            }
            target.related.add(node.id);
          }
        } else if ((node.content.value as any).formula.value) {
          this.buildFormulaRelated((<any>node.content.value).formula, node.id);
        }
      }
    }
  }
  //   递归判断公式中的数据源
  buildFormulaRelated(formula: any, id: string) {
    if (formula.fieldType === 'datasource') {
      let value = formula.value.match(/[^-]*/);
      if (value) {
        let target = this.allDataSourceConfig.find(a => a.id === value[0]);
        if (target) {
          if (!target.related) {
            target.related = new Set();
          }
          target.related.add(id);
        }
      }
    }
    if (formula.children && formula.children.length) {
      for (let i of formula.children) {
        this.buildFormulaRelated(i, id);
      }
    }
  }
  getTip(v, cb) {
    cb(v ? this.tip.filter(a => a.value.match(v)) : this.tip);
  }
  created() {
    getWidgetConfigStruct({}).then(res => {
      this.tags = res.data.nodeTypes.map(a => {
        return { name: a, children: [] };
      });
    });
    this.allDataSourceConfig = this.widget.dataSourceConfigs || [];
    this.allDataSource = this.allDataSourceConfig;
    this.node.node = this.widget.node || {
      name: 'div',
      props: [{ name: 'class', value: 'node-root' }],
      children: []
    };
    if (this.node.node.children.length) {
      this.checkDataSourceRelated();
    }
  }
}
