var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.widget.compType === "html",
          expression: "widget.compType === 'html'",
        },
      ],
    },
    [
      [
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_name") } },
          [
            _c("lang-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compName,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compName", $$v)
                },
                expression: "widget.compName",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_code") } },
          [
            _c("el-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compCode,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compCode", $$v)
                },
                expression: "widget.compCode",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_display_label") } },
          [
            _c("el-switch", {
              model: {
                value: _vm.widget.properties.showLabel,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "showLabel", $$v)
                },
                expression: "widget.properties.showLabel",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_label_width") } },
          [
            _c("el-input-number", {
              attrs: { min: 0, max: 500 },
              model: {
                value: _vm.widget.properties.labelWidth,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "labelWidth", $$v)
                },
                expression: "widget.properties.labelWidth",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_label_position") } },
          [
            _c(
              "el-select",
              {
                model: {
                  value: _vm.widget.properties.labelPosition,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "labelPosition", $$v)
                  },
                  expression: "widget.properties.labelPosition",
                },
              },
              [
                _c("el-option", {
                  attrs: { label: _vm.$t("lang_align_left"), value: "left" },
                }),
                _c("el-option", {
                  attrs: { label: _vm.$t("lang_center"), value: "center" },
                }),
                _c("el-option", {
                  attrs: { label: _vm.$t("lang_align_right"), value: "right" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_label_border") } },
          [
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.widget.properties.cellBorder,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "cellBorder", $$v)
                  },
                  expression: "widget.properties.cellBorder",
                },
              },
              [
                _c("el-radio", { attrs: { label: true } }, [
                  _vm._v(_vm._s(_vm.$t("lang_display"))),
                ]),
                _c("el-radio", { attrs: { label: false } }, [
                  _vm._v(_vm._s(_vm.$t("lang_hide"))),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_text") } },
          [
            _c("el-button", {
              attrs: { icon: "el-icon-edit-outline", circle: "" },
              on: { click: _vm.handlerEditMore },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_operation_status") } },
          [
            _c(
              "el-select",
              {
                attrs: {
                  placeholder: _vm.$t("lang_please_select"),
                  clearable: "",
                },
                model: {
                  value: _vm.widget.properties.operation,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "operation", $$v)
                  },
                  expression: "widget.properties.operation",
                },
              },
              _vm._l(_vm.statusList, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.label, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
      ],
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.editTextVisible,
            width: "70%",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "show-close": false,
            center: true,
            top: "20px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editTextVisible = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.current,
                callback: function ($$v) {
                  _vm.current = $$v
                },
                expression: "current",
              },
            },
            [
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    name: "datasource",
                    label: _vm.$t("lang_configure_data_source1"),
                  },
                },
                [
                  _c(
                    "el-row",
                    _vm._l(_vm.dataSourceConfigs, function (item) {
                      return _c(
                        "el-button",
                        {
                          key: item.type,
                          staticClass: "datasource-type",
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.openDataSourceConfigDialog(item)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    1
                  ),
                  _vm._l(_vm.allDataSourceConfig, function (item, index) {
                    return _c(
                      "el-row",
                      { key: item.id, staticClass: "datasource-row" },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(item.name) + "(" + _vm._s(item.id) + ")"
                          ),
                        ]),
                        _c(
                          "button",
                          {
                            staticClass: "edit-btn",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.editDataSourceConfigDialog(item)
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-edit" })]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "delete-btn",
                            attrs: { type: "button", disabled: item.link },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.deleteDataSourceConfig(index)
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-delete" })]
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
              _c(
                "el-tab-pane",
                { attrs: { name: "html", label: "配置结构" } },
                [
                  _c(
                    "el-row",
                    { staticClass: "html-build" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "html-build-left", attrs: { span: 6 } },
                        [
                          _c("el-divider", [_vm._v("可用标签")]),
                          _c(
                            "div",
                            { staticClass: "tags-container" },
                            [
                              _c(
                                "draggable",
                                {
                                  staticClass: "tags-container",
                                  attrs: {
                                    list: _vm.tags,
                                    group: {
                                      name: "tree",
                                      pull: "clone",
                                      put: false,
                                    },
                                    "chosen-class": "chosen-tag",
                                  },
                                },
                                _vm._l(_vm.tags, function (tag, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: "tag_" + index,
                                      staticClass: "avaliable-tag",
                                      attrs: { "data-type": tag.name },
                                    },
                                    [_vm._v(_vm._s(tag.name))]
                                  )
                                }),
                                0
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "html-build-right",
                          attrs: { span: 18 },
                        },
                        [
                          _c("el-divider", [_vm._v("模板树")]),
                          _c(
                            "div",
                            { staticClass: "node-root" },
                            [
                              _vm._v(" 根节点 "),
                              _c("HtmlNode", {
                                attrs: { node: _vm.node.node.children },
                                on: {
                                  "update:node": function ($event) {
                                    return _vm.$set(
                                      _vm.node.node,
                                      "children",
                                      $event
                                    )
                                  },
                                  edit: _vm.handleEditNode,
                                  delete: _vm.deleteNode,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.vis_node
            ? _c(
                "el-dialog",
                {
                  attrs: {
                    visible: _vm.vis_node,
                    "close-on-click-modal": false,
                    title: _vm.$t("lang_edit"),
                    modal: false,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.vis_node = $event
                    },
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      attrs: { model: _vm.currentNode, "label-width": "100px" },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "标签名" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      readonly: "",
                                      value: _vm.currentNode.name,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._l(_vm.currentNode.props, function (col) {
                            return _c(
                              "el-col",
                              { key: col.name, attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: col.name } },
                                  [
                                    col.name === "class"
                                      ? _c("el-autocomplete", {
                                          attrs: {
                                            "fetch-suggestions": _vm.getTip,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(item.value)
                                                      ),
                                                    ]),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(item.label)
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: col.value,
                                            callback: function ($$v) {
                                              _vm.$set(col, "value", $$v)
                                            },
                                            expression: "col.value",
                                          },
                                        })
                                      : col.name !== "href"
                                      ? _c("el-input", {
                                          attrs: { type: "textarea" },
                                          model: {
                                            value: col.value,
                                            callback: function ($$v) {
                                              _vm.$set(col, "value", $$v)
                                            },
                                            expression: "col.value",
                                          },
                                        })
                                      : _c("copy-formula-config", {
                                          attrs: {
                                            dataSourceConfig:
                                              _vm.allDataSourceConfig,
                                          },
                                          on: {
                                            change: function (e) {
                                              return _vm.changeHref(e, col)
                                            },
                                          },
                                          model: {
                                            value: col.value,
                                            callback: function ($$v) {
                                              _vm.$set(col, "value", $$v)
                                            },
                                            expression: "col.value",
                                          },
                                        }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                      _c("el-divider", [_vm._v("数据配置")]),
                      _c(
                        "el-row",
                        { attrs: { type: "flex", gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: { change: _vm.changeType },
                                  model: {
                                    value: _vm.currentNode.content.type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.currentNode.content,
                                        "type",
                                        $$v
                                      )
                                    },
                                    expression: "currentNode.content.type",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "constants" } },
                                    [_vm._v(_vm._s(_vm.$t("lang_constant")))]
                                  ),
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "datasource" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "lang_data_source_onfiguration"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "components" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("lang_component_name"))
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "formula" } },
                                    [_vm._v("公式")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _vm.currentNode.content.type !== "datasource"
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "标签内容" } },
                                    [
                                      _vm.currentNode.content.type ===
                                      "components"
                                        ? _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "请选择组件",
                                              },
                                              on: {
                                                change: _vm.changeComponent,
                                              },
                                              model: {
                                                value:
                                                  _vm.currentNode.content.value,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.currentNode.content,
                                                    "value",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "currentNode.content.value",
                                              },
                                            },
                                            _vm._l(
                                              _vm._componentsMap,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.compCode,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.compCode,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm.currentNode.content.type ===
                                          "constants"
                                        ? _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入常量",
                                            },
                                            on: { change: _vm.changeConstant },
                                            model: {
                                              value:
                                                _vm.currentNode.content.value,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.currentNode.content,
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "currentNode.content.value",
                                            },
                                          })
                                        : _vm.currentNode.content.type ===
                                          "formula"
                                        ? _c("ele-formula-config", {
                                            ref: "formula",
                                            on: {
                                              change: function (e, label) {
                                                return _vm.changeFormula(
                                                  e,
                                                  _vm.currentNode,
                                                  label
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.currentNode.content.value,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.currentNode.content,
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "currentNode.content.value",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _c(
                                    "el-form-item",
                                    { attrs: { label: "是否循环" } },
                                    [
                                      _c("el-switch", {
                                        on: { change: _vm.changeLoop },
                                        model: {
                                          value:
                                            _vm.currentNode.content.value.loop,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.currentNode.content.value,
                                              "loop",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "currentNode.content.value.loop",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.currentNode.content.type === "datasource"
                        ? _c(
                            "el-row",
                            { attrs: { type: "flex" } },
                            [
                              _vm.currentNode.content.value.loop
                                ? _c(
                                    "el-col",
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lang_source_type"),
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder:
                                                  _vm.$t("lang_source_type"),
                                              },
                                              on: {
                                                change: _vm.changeDataSource,
                                              },
                                              model: {
                                                value:
                                                  _vm.currentNode.content.value
                                                    .id,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.currentNode.content
                                                      .value,
                                                    "id",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "currentNode.content.value.id",
                                              },
                                            },
                                            _vm._l(
                                              _vm.allDataSourceConfig,
                                              function (datasource) {
                                                return _c("el-option", {
                                                  key: datasource.id,
                                                  attrs: {
                                                    label: datasource.name,
                                                    value: datasource.id,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-col",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.currentNode.content.value
                                          .loop
                                          ? "列"
                                          : "公式",
                                      },
                                    },
                                    [
                                      !_vm.currentNode.content.value.loop
                                        ? _c("ele-formula-config-for-html", {
                                            ref: "formula",
                                            attrs: {
                                              dataSourceConfig:
                                                _vm.allDataSourceConfig,
                                            },
                                            on: {
                                              change: function (e, label) {
                                                return _vm.changeFormula(
                                                  e,
                                                  _vm.currentNode,
                                                  label
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.currentNode.content.value
                                                  .formula,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.currentNode.content.value,
                                                  "formula",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "currentNode.content.value.formula",
                                            },
                                          })
                                        : _c(
                                            "el-select",
                                            {
                                              on: { change: _vm.changeColumn },
                                              model: {
                                                value:
                                                  _vm.currentNode.content.value
                                                    .column,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.currentNode.content
                                                      .value,
                                                    "column",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "currentNode.content.value.column",
                                              },
                                            },
                                            _vm._l(
                                              _vm._selectedCol,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.key,
                                                  attrs: {
                                                    value: item.key,
                                                    label: item.title,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-row",
                        { attrs: { type: "flex", justify: "space-around" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.closeNodeEdit },
                            },
                            [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.closeNodeEdit },
                            },
                            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("ele-data-source-config-dialog", {
            staticClass: "html-datasource",
            attrs: { visible: _vm.vis_source, widget: _vm.cachedWidget },
            on: {
              save: _vm.saveDataSourceConfig,
              "update:visible": function ($event) {
                _vm.vis_source = $event
              },
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlerSave } },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }