var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "draggable",
    {
      staticClass: "node-draggable",
      attrs: {
        tag: "div",
        list: _vm.node,
        value: _vm.node,
        group: { name: "tree", pull: true, put: true },
      },
      on: { add: _vm.changeHandler },
    },
    _vm._l(_vm.node, function (item, index) {
      return _c(
        "div",
        { key: item.path, staticClass: "node-item" },
        [
          _vm._v(" " + _vm._s(item.name)),
          _c("span", { staticClass: "node-label" }, [
            _vm._v(_vm._s(item.content && item.content.name)),
          ]),
          _c("i", {
            staticClass: "el-icon-edit edit-icon",
            on: {
              click: function ($event) {
                return _vm.editHandler(item, _vm.node, index)
              },
            },
          }),
          _c("i", {
            staticClass: "el-icon-delete delete-icon",
            on: {
              click: function ($event) {
                return _vm.deleteHandler(index)
              },
            },
          }),
          _c("html-node", {
            attrs: { node: item.children },
            on: {
              "update:node": function ($event) {
                return _vm.$set(item, "children", $event)
              },
              edit: _vm.editHandler,
              delete: function ($event) {
                return _vm.$emit("delete")
              },
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }