




































































































import { Component, Prop, Mixins } from 'vue-property-decorator';
import EleFormulaConfig from './ele-formula-config.vue';
import FormualNested from './components/formual-nested.vue';
@Component({
  name: 'EleFormulaConfigForHtml'
})
class EleFormulaItemForHtml extends Mixins(FormualNested) {
  isObjectField(widget) {
    return widget.type === 'datasource';
  }
}
@Component({
  components: {
    FormualNested: EleFormulaItemForHtml
  }
})
export default class EleFormulaConfigForHtml extends Mixins(EleFormulaConfig) {
  @Prop({ default: () => [] }) dataSourceConfig!: any[];
  buildWidgetTree() {
    this.fieldTree = [];
    this.dataSourceConfig.forEach(a => {
      for (let column of a.columnConfig.customComplexColumns) {
        this.fieldTree.push({
          parentComponents: undefined,
          code: a.id + '-' + column.key,
          text: a.name + '[' + column.title + ']',
          dataType: 'COLLECTOR',
          columnKey: column.key,
          disabled: false,
          type: 'datasource'
        });
      }
    });
  }
}
